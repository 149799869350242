import React, { useEffect, useState } from 'react'
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import CngSection from 'src/components/cngcomponents/CngSection'
import Response from './Response'
import StatusNotification from './StatusNotification'
import Grid from '@material-ui/core/Grid'

const { CngTabs } = components
const {
  filter: { EQUAL }
} = constants

function ResponseLog(props) {
  const { manifestId } = props

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CngSection title="Response">
          <Response
            id={manifestId}
            isViewOnly={true}
          />
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection title="Status Notification">
          <StatusNotification
            id={manifestId}
            isViewOnly={true}
          />
        </CngSection>
      </Grid>
    </Grid>
  )
}

export default ResponseLog
