import React, { useState, useRef } from 'react'
import {
  Typography
} from '@material-ui/core'
import { getStatusMetadata } from 'src/common/NACommon'
import { constants,components,useTranslation,useServices } from 'cng-web-lib'
import { useHistory } from 'react-router-dom'
import { FileForUserGetPartyId } from 'src/common/FileForUserCommon'
import Table from 'src/components/aciacehighway/Table'
import AceOcnManifestApiUrls from 'src/apiUrls/AceOcnManifestApiUrls'
import pathMap from 'src/paths/pathMap'
import AceOcnManifestKeys from 'src/constants/locale/key/AceOcnManifest'
import Namespace from 'src/constants/locale/Namespace'
import AceOcnVesselDepartureApiUrls from 'src/apiUrls/AceOcnVesselDepartureApiUrls'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    DateRangeFilter: CngDateRangeFilter,
    useDefaultNotification
  },
  button: { CngButton, CngIconButton },
  CngDialog
} = components

const {
  locale: {
    key: { UiComponentKeys }
  },
  filter: { EQUAL, LIKE, IN, BETWEEN }
} = constants

function TemplateSelectDialogWithOptionACEOceanVesselDeparture(props) {
  const {
    manifestTemplateDialog,
    showNotification,
    title,
    handleClose
  } = props

  const {
    filter: { LIKE, EQUAL }
  } = constants

  const history = useHistory()
  const tableRef = useRef(null)
  const { fetchRecords } = useServices()

  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.ACE_OCN_MANIFEST
  ])

  const translatedTextsObject = makeTranslatedTextsObject()

  function cloneVesselDepartureFromManifest(rowData){
    let data = {}
    data.manifestHeaderId = rowData.id
    data.carrierScac = rowData.scac.toUpperCase().trim()
    data.vesselName = rowData.vesselName.toUpperCase().trim()
    data.voyageNumber = rowData.voyageNumber.toUpperCase().trim()
    data.vesselCountry = rowData.vesselCountry
    data.portOfDischarge = rowData.portOfDischarge
    data.eta = rowData.etaDate
    data.modeOfTransport = rowData.modeOfTransport
    data.modeOfSubmission= "UI"
    history.push({pathname: `${pathMap.ACE_OCN_VESSEL_DEPARTURE_ADD_VIEW}`, state: data})
  }

  function makeTranslatedTextsObject() {
    let aceOcnManifest = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: aceOcnManifest
      }
    )
    let scac = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.SCAC
    )
    let vesselName = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.VESSEL_NAME
    )
    let voyageNumber = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.VOYAGE_NUMBER
    )
    let vesselCountry = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.VESSEL_COUNTRY
    )
    let portOfDischarge = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.PORT_OF_DISCHARGE
    )
    let etaDate = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.E_T_A
    )
    let modeOfTransport = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.MODE_OF_TRANSPORT
    )
    let paperlessInbondParticipant = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.PAPERLESS_INBOND_PARTICIPANT
    )
    let issuerScac = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.ISSUER_SCAC
    )
    let partyId = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.PARTY_ID
    )
    let submittedBy = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.SUBMITTED_BY
    )
    let submittedDate = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.SUBMITTED_DATE
    )
    let modeOfSubmission = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.MODE_OF_SUBMISSION
    )
    let status = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.STATUS
    )
    let messageFunCode = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.MESSAGE_FUN_CODE
    )
    let saveButton = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.SAVE_BUTTON
    )

    let editButton = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.EDIT_BUTTON
    )

    let viewButton = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.VIEW_BUTTON
    )

    let cloneButton = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.CLONE_BUTTON
    )

    let deleteButton = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.DELETE_BUTTON
    )

    let updateButton = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.UPDATE_BUTTON
    )

    let submitButton = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.SUBMIT_BUTTON
    )

    let editSentRecord = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.EDIT_SENT_RECORDS
    )
    let createdDate = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.CREATED_DATE
    )
    let responseDate = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.RESPONSE_DATE
    )
    let updatedDate = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.UPDATED_DATE
    )

    let closeButton = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.CLOSE_BUTTON
    )

    return {
      aceOcnManifest,
      tableTitle,
      scac,
      vesselName,
      voyageNumber,
      vesselCountry,
      portOfDischarge,
      etaDate,
      modeOfTransport,
      paperlessInbondParticipant,
      issuerScac,
      partyId,
      submittedBy,
      submittedDate,
      modeOfSubmission,
      status,
      messageFunCode,
      saveButton,
      editButton,
      viewButton,
      cloneButton,
      deleteButton,
      updateButton,
      submitButton,
      editSentRecord,
      createdDate,
      responseDate,
      updatedDate,
      closeButton
    }
  }

  const columns = [
    {
      field: "scac",
      sortKey: "scac",
      title: translatedTextsObject.scac,
    },
    {
      field: "vesselName",
      sortKey: "vesselName",
      title: translatedTextsObject.vesselName,
    },
    {
      field: "voyageNumber",
      sortKey: "voyageNumber",
      title: translatedTextsObject.voyageNumber,
    },
    {
      field: "portOfDischarge",
      sortKey: "portOfDischarge",
      title: translatedTextsObject.portOfDischarge,
    },
    {
      field: "etaDate",
      sortKey: "etaDate",
      title: translatedTextsObject.etaDate,
      type: "date",
      filterComponent: CngDateRangeFilter,
    },
    {
      field: "issuerScac",
      sortKey: "issuerScac",
      title: translatedTextsObject.issuerScac,
    },
    {
      field: "status",
      sortKey: "status",
      title: translatedTextsObject.status,
      render: (rowData) => {
        const { color, label } = getStatusMetadata(rowData.status)
        return <Typography style={{ color }}>{label}</Typography>
      }
    },
    {
      field: "submittedDate",
      sortKey: "submittedDate",
      title: translatedTextsObject.submittedDate,
      type: "date",
      filterComponent: CngDateRangeFilter,
    },
    {
      field: "responseDate",
      sortKey: "responseDate",
      title: translatedTextsObject.responseDate,
    },
    {
      field: "createdDate",
      sortKey: "createdDate",
      title: translatedTextsObject.createdDate,
    },
    {
      field: "updatedDate",
      sortKey: "updatedDate",
      title: translatedTextsObject.updatedDate,
    }
  ]

  const filters = [
    {
      label: translatedTextsObject.scac,
      type: 'textfield',
      name: 'scac',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.vesselName,
      type: 'textfield',
      name: 'vesselName',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.voyageNumber,
      type: 'textfield',
      name: 'voyageNumber',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.portOfDischarge,
      type: 'textfield',
      name: 'portOfDischarge',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.etaDate,
      type: 'daterange',
      name: 'etaDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.issuerScac,
      type: 'textfield',
      name: 'issuerScac',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.status,
      type: 'checkbox',
      name: 'status',
      operatorType: IN,
      options: [
        {
          fullWidth: true,
          label: 'Draft',
          value: '1005',
          filterValue: { value: '1005' }
        },
        {
          fullWidth: true,
          label: 'Accepted',
          value: '1000',
          filterValue: { value: '1000' }
        },
        {
          fullWidth: true,
          label: 'Sent',
          value: '1008',
          filterValue: { value: '1008' }
        },
        {
          fullWidth: true,
          label: 'Rejected',
          value: '1001',
          filterValue: { value: '1001' }
        },
        {
          fullWidth: true,
          label: 'Modification Rejected',
          value: '1007',
          filterValue: { value: '1007' }
        },
        {
          fullWidth: true,
          label: 'Cancel Accepted',
          value: '1003',
          filterValue: { value: '1003' }
        },
        {
          fullWidth: true,
          label: 'Cancel Rejected',
          value: '1004',
          filterValue: { value: '1004' }
        },
        {
          fullWidth: true,
          label: 'Status Notification',
          value: '1006',
          filterValue: { value: '1006' }
        }
      ]
    },
    {
      label: translatedTextsObject.submittedDate,
      type: 'daterange',
      name: 'submittedDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.responseDate,
      type: 'daterange',
      name: 'responseDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.createdDate,
      type: 'daterange',
      name: 'createdDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.createdDate,
      type: 'daterange',
      name: 'updatedDate',
      operatorType: BETWEEN
    }
  ]

  return (
    <CngDialog
      dialogContent={
    <Table
    actions={[
    ]}
    columns={columns}
    compact
    //exportData={{ url: AceOcnManifestApiUrls.EXPORT }}
    fetch={{ url: AceOcnManifestApiUrls.GET }}
    fetchFilters={[
       { 
        field: 'partyId', 
        operator: EQUAL, 
        value: FileForUserGetPartyId() 
      },
      {
        field: 'status', 
        operator: EQUAL, 
        value: '1000' 
      }
     ]}
    filters={filters}
    onRowClick={(rowData) => {

      fetchRecords.execute(
        AceOcnVesselDepartureApiUrls.GET,
        {
          filters: [{ field: 'manifestHeaderId', operator: 'EQUAL', value: rowData.id }],
        },
        (res) => {
          if (res.content.length > 0) {
            let sentStatus = false;
            let sentStatusId = 0;
            let allowedEdit = false;
            let allowedEditStatusId = 0;
            for(let i=0; i < res.content.length; i++){
                if(res.content[i].departureStatus === "1005" || res.content[i].departureStatus==="1001"){
                  allowedEdit = true;
                  allowedEditStatusId = res.content[i].id;
                }
                if(res.content[i].departureStatus === "1008"){
                  sentStatus = true;
                  sentStatusId = res.content[i].id;
                }
            }
            //if vessel departure = draft or rejected then allow to edit
            if(allowedEdit){
              history.push(`${pathMap.ACE_OCN_VESSEL_DEPARTURE}/edit/${allowedEditStatusId}`)
            }
            else{
              //if vessel departure = sent
              if(sentStatus){
                history.push(`${pathMap.ACE_OCN_VESSEL_DEPARTURE}/view/${sentStatusId}`)
              }else{
                cloneVesselDepartureFromManifest(rowData)
              }
            }
          }else{
            cloneVesselDepartureFromManifest(rowData)
          }
        }
      )
    }}
    rowActions={[
     
    ]}
    showNotification={showNotification}
    sortConfig={{
      type: 'column',
      defaultField: 'createdDate',
      defaultDirection: 'DESC'
    }}
    tableRef={tableRef}
  />}
  dialogTitle={title}
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      fullWidth
      maxWidth='md'
      onClose={handleClose}
      open={manifestTemplateDialog}
      dialogAction={
        <>
          <CngButton color='secondary' 
          onClick={handleClose}
          >
            {translatedTextsObject.closeButton}
          </CngButton>
        </>
      }
      shouldShowCloseButton
  />
  )
}

export default TemplateSelectDialogWithOptionACEOceanVesselDeparture