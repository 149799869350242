import makeValidationSchema from './AciAirItemListMakeValidationSchema'
import { components, constants, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import AciAirKeys from 'src/constants/locale/key/AciAir'
import { Divider, Grid, Typography } from '@material-ui/core'
import { NaDangerousGoodsAutocompleteField, NaHSCodeAutocompleteField, NaProductMasterAutoCompleteField } from 'src/components/na'
import { useFormContext } from "react-hook-form";
import CngSection from '../../../components/cngcomponents/CngSection'

const {
  form: {
    field: {
      CngCheckboxField,
      CngTextField,
      CngUomAutocompleteField,
      CngCodeMasterAutocompleteField
    },
  },
  CngGridItem,
} = components

const { filter: { EQUAL } } = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  saveFlag: false,
  productIdentification: "",
  itemNo: "",
  ladingQuantity: "",
  ladingUom: "",
  productDesc: "",
  productDesc1: "",
  productDesc2: "",
  productDesc3: "",
  productDesc4: "",
  productDesc5: "",
  productDesc6: "",
  productDesc7: "",
  productDesc8: "",
  grossWeightUom: "",
  grossWeight: "",
  measurementUom: "",
  measurementVol: "",
  undgCode: "",
  markNo: "",
  hsCode: ""
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.ACI_AIR)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { setValue, watch } = useFormContext()

  const saveFlag = watch('saveFlag')

  function makeTranslatedTextsObject() {
    let productDropDown = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.PRODUCT_DROP_DOWN
    )
    let saveFlag = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.SAVE_FLAG
    )
    let productIdentification = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.PRODUCT_IDENTIFICATION
    )
    let itemNo = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.ITEM_NO
    )
    let ladingQuantity = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.LADING_QUANTITY
    )
    let ladingUom = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.LADING_UOM
    )
    let productDesc = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.PRODUCT_DESC
    )
    let productDesc1 = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.PRODUCT_DESC1
    )
    let productDesc2 = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.PRODUCT_DESC2
    )
    let productDesc3 = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.PRODUCT_DESC3
    )
    let productDesc4 = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.PRODUCT_DESC4
    )
    let productDesc5 = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.PRODUCT_DESC5
    )
    let productDesc6 = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.PRODUCT_DESC6
    )
    let productDesc7 = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.PRODUCT_DESC7
    )
    let productDesc8 = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.PRODUCT_DESC8
    )
    let grossWeightUom = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.GROSS_WEIGHT_UOM
    )
    let grossWeight = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.GROSS_WEIGHT
    )
    let measurementUom = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.MEASUREMENT_UOM
    )
    let measurementVol = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.MEASUREMENT_VOL
    )
    let undgCode = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.UNDG_CODE
    )
    let markNo = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.MARK_NO
    )
    let hsCode = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirItemList.HS_CODE
    )

    return {
      productDropDown,
      saveFlag,
      productIdentification,
      itemNo,
      ladingQuantity,
      ladingUom,
      productDesc,
      productDesc1,
      productDesc2,
      productDesc3,
      productDesc4,
      productDesc5,
      productDesc6,
      productDesc7,
      productDesc8,
      grossWeightUom,
      grossWeight,
      measurementUom,
      measurementVol,
      undgCode,
      markNo,
      hsCode
    }
  }

  function handleApplyTemplate(template) {
    if (template !== null) {
      const {
        productDesc1,
        productDesc2,
        productDesc3,
        productDesc4,
        productDesc5,
        productDesc6,
        productDesc7,
        productDesc8,
        productDesc9,
        hsCode
      } = template.data

      setValue('productDesc', productDesc1 || '')
      setValue('productDesc1', productDesc2 || '')
      setValue('productDesc2', productDesc3 || '')
      setValue('productDesc3', productDesc4 || '')
      setValue('productDesc4', productDesc5 || '')
      setValue('productDesc5', productDesc6 || '')
      setValue('productDesc6', productDesc7 || '')
      setValue('productDesc7', productDesc8 || '')
      setValue('productDesc8', productDesc9 || '')
      setValue('hsCode', hsCode || '')
    } else {
      setValue('productDesc', '')
      setValue('productDesc1', '')
      setValue('productDesc2', '')
      setValue('productDesc3', '')
      setValue('productDesc4', '')
      setValue('productDesc5', '')
      setValue('productDesc6', '')
      setValue('productDesc7', '')
      setValue('productDesc8', '')
      setValue('hsCode', '')
    }
  }

	return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={8}>
            <Grid container spacing={1}>
              <CngGridItem xs={12} md='auto' shouldHide={shouldHideMap?.saveFlag}>
                <CngCheckboxField
                  label={translatedTextsObject.saveFlag}
                  name='saveFlag'
                  disabled={disabled}
                  onChange={(e) => setValue('saveFlag', e.target.checked)}
                  size='small'
                  style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
                />
              </CngGridItem>
              <CngGridItem xs={12} md shouldHide={shouldHideMap?.productIdentification}>
                <CngTextField
                  name='productIdentification'
                  inputProps={{ maxLength: 35 }}
                  label={translatedTextsObject.productIdentification}
                  isRequired={saveFlag}
                  disabled={disabled}
                  size='small'
                  onBlur={(e) =>
                    setValue(
                      'productIdentification',
                      e.target.value.toUpperCase().trim(),
                      { shouldValidate: true }
                    )
                  }
                />
              </CngGridItem>
            </Grid>
          </Grid>
          <Grid item xs='auto'>
            <Divider orientation='vertical' />
          </Grid>
          <CngGridItem xs={12} md shouldHide={shouldHideMap?.productDropDown}>
            <NaProductMasterAutoCompleteField
              name='productDropDown'
              label={translatedTextsObject.productDropDown}
              size='small'
              disabled={disabled}
              onChange={(_, options) => handleApplyTemplate(options)}
            />
          </CngGridItem>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CngSection
          title='Classification & Weight'
          subheader={
            <Typography color='textSecondary' variant='caption'>
              <Typography variant='inherit' color='error'>*</Typography>
              Mandatory fields
            </Typography>
          }
        >
          <Grid container spacing={1}>
            <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.itemNo}>
              <CngTextField
                name='itemNo'
                inputProps={{ maxLength: 4 }}
                label={translatedTextsObject.itemNo}
                isRequired
                disabled={disabled}
                size='small'
                onBlur={(e) =>
                  setValue(
                    'itemNo',
                    e.target.value.toUpperCase().trim(),
                    { shouldValidate: true }
                  )
                }
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={9} shouldHide={shouldHideMap?.productDesc}>
              <CngTextField
                name='productDesc'
                inputProps={{ maxLength: 50 }}
                label={translatedTextsObject.productDesc}
                isRequired
                disabled={disabled}
                size='small'
                onBlur={(e) =>
                  setValue(
                    'productDesc',
                    e.target.value.toUpperCase().trim(),
                    { shouldValidate: true }
                  )
                }
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.productDesc1}>
              <CngTextField
                name='productDesc1'
                inputProps={{ maxLength: 50 }}
                label={translatedTextsObject.productDesc1}
                disabled={disabled}
                size='small'
                onBlur={(e) =>
                  setValue(
                    'productDesc1',
                    e.target.value.toUpperCase().trim(),
                    { shouldValidate: true }
                  )
                }
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.productDesc2}>
              <CngTextField
                name='productDesc2'
                inputProps={{ maxLength: 50 }}
                label={translatedTextsObject.productDesc2}
                disabled={disabled}
                size='small'
                onBlur={(e) =>
                  setValue(
                    'productDesc2',
                    e.target.value.toUpperCase().trim(),
                    { shouldValidate: true }
                  )
                }
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.productDesc3}>
              <CngTextField
                name='productDesc3'
                inputProps={{ maxLength: 50 }}
                label={translatedTextsObject.productDesc3}
                disabled={disabled}
                size='small'
                onBlur={(e) =>
                  setValue(
                    'productDesc3',
                    e.target.value.toUpperCase().trim(),
                    { shouldValidate: true }
                  )
                }
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.productDesc4}>
              <CngTextField
                name='productDesc4'
                inputProps={{ maxLength: 50 }}
                label={translatedTextsObject.productDesc4}
                disabled={disabled}
                size='small'
                onBlur={(e) =>
                  setValue(
                    'productDesc4',
                    e.target.value.toUpperCase().trim(),
                    { shouldValidate: true }
                  )
                }
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.productDesc5}>
              <CngTextField
                name='productDesc5'
                inputProps={{ maxLength: 50 }}
                label={translatedTextsObject.productDesc5}
                disabled={disabled}
                size='small'
                onBlur={(e) =>
                  setValue(
                    'productDesc5',
                    e.target.value.toUpperCase().trim(),
                    { shouldValidate: true }
                  )
                }
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.productDesc6}>
              <CngTextField
                name='productDesc6'
                inputProps={{ maxLength: 50 }}
                label={translatedTextsObject.productDesc6}
                disabled={disabled}
                size='small'
                onBlur={(e) =>
                  setValue(
                    'productDesc6',
                    e.target.value.toUpperCase().trim(),
                    { shouldValidate: true }
                  )
                }
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.productDesc7}>
              <CngTextField
                name='productDesc7'
                inputProps={{ maxLength: 50 }}
                label={translatedTextsObject.productDesc7}
                disabled={disabled}
                size='small'
                onBlur={(e) =>
                  setValue(
                    'productDesc7',
                    e.target.value.toUpperCase().trim(),
                    { shouldValidate: true }
                  )
                }
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.productDesc8}>
              <CngTextField
                name='productDesc8'
                inputProps={{ maxLength: 50 }}
                label={translatedTextsObject.productDesc8}
                disabled={disabled}
                size='small'
                onBlur={(e) =>
                  setValue(
                    'productDesc8',
                    e.target.value.toUpperCase().trim(),
                    { shouldValidate: true }
                  )
                }
              />
            </CngGridItem>
          </Grid>
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.ladingQuantity}>
            <CngTextField
              name='ladingQuantity'
              inputProps={{ maxLength: 7 }}
              label={translatedTextsObject.ladingQuantity}
              isRequired
              disabled={disabled}
              size='small'
              onBlur={(e) =>
                setValue('ladingQuantity', e.target.value.trim(), {
                  shouldValidate: true
                })
              }
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.ladingUom}>
            <CngUomAutocompleteField
              name='ladingUom'
              inputProps={{ maxLength: 3 }}
              label={translatedTextsObject.ladingUom}
              isRequired
              disabled={disabled}
              size='small'
              lookupProps={{
                label: (record) =>
                  [record.code, record.descriptionEn].join('; '),
                filters: [{ field: 'uomType', operator: EQUAL, value: 'ACI' }]
              }}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.grossWeight}>
            <CngTextField
              name='grossWeight'
              inputProps={{ maxLength: 14 }}
              label={translatedTextsObject.grossWeight}
              isRequired
              disabled={disabled}
              size='small'
              onBlur={(e) =>
                setValue('grossWeight', e.target.value.trim(), {
                  shouldValidate: true
                })
              }
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.grossWeightUom}>
            <CngCodeMasterAutocompleteField
              codeType='GROSS_WEIGHT_UOM'
              name='grossWeightUom'
              label={translatedTextsObject.grossWeightUom}
              isRequired
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.measurementVol}>
            <CngTextField
              name='measurementVol'
              inputProps={{ maxLength: 14 }}
              label={translatedTextsObject.measurementVol}
              isRequired
              disabled={disabled}
              size='small'
              onBlur={(e) =>
                setValue('measurementVol', e.target.value.trim(), {
                  shouldValidate: true
                })
              }
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.measurementUom}>
            <CngCodeMasterAutocompleteField
              codeType='VOLUME_UOM'
              name='measurementUom'
              label={translatedTextsObject.measurementUom}
              isRequired
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.hsCode}>
            <NaHSCodeAutocompleteField
              name='hsCode'
              label={translatedTextsObject.hsCode}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.undgCode}>
            <NaDangerousGoodsAutocompleteField
              name='undgCode'
              label={translatedTextsObject.undgCode}
              disabled={disabled}
              size='small'
              lookupProps={{
                filters: [{ field: 'indicator', operator: EQUAL, value: 'ACI' }]
              }}
            />
          </CngGridItem>
          <CngGridItem xs={12} shouldHide={shouldHideMap?.markNo}>
            <CngTextField
              name='markNo'
              inputProps={{ maxLength: 35 }}
              label={translatedTextsObject.markNo}
              disabled={disabled}
              size='small'
              onBlur={(e) =>
                setValue('markNo', e.target.value.toUpperCase().trim(), {
                  shouldValidate: true
                })
              }
            />
          </CngGridItem>
        </Grid>
      </Grid>
    </Grid>
	)
}

const AciAirItemListFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default AciAirItemListFormProperties
