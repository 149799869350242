import React, { useRef, useState, useContext, useEffect } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import { Chip } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ACEOceanTranslationContext } from '../contexts/ACEOceanTranslationContext'
import pathMap from 'src/paths/pathMap'
import AceOcnBillofladingApiUrls from 'src/apiUrls/AceOcnBillofladingApiUrls'
import Table from 'src/components/aciacehighway/Table'
import ConfirmDialog from 'src/views/common/ConfirmDialog'
import { getStatusMetadata } from '../../../common/NACommon'
import { FileForUserGetPartyId, GetFilterByUserIdCriteria, FileForUserGetCurrentLoginId } from 'src/common/FileForUserCommon'
import TemplateSelectDialogWithOptionACEOCN from 'src/views/aceocean/billoflading/TemplateSelectDialogWithOptionACEOCN'
import { format } from 'date-fns'
import sweetalert from 'sweetalert'
import moment from 'moment-timezone'
import _ from 'lodash'
import UserProfileApiUrls from "src/apiUrls/UserProfileApiUrls";

const { 
  table: { 
    useFetchCodeMaintenanceLookup 
  } 
} = components

const { filter: { EQUAL, IN, LIKE, BETWEEN } } = constants

function TablePage(props) {
  const { history, location, showNotification } = props
  const { securedSendRequest, deleteRecord } = useServices()
  const { getTranslatedText } = useContext(ACEOceanTranslationContext)
  const [billTemplateDialog, setBillTemplateDialog] = useState(false)

  const tableRef = useRef(null)

  const [confirmDelete, setConfirmDelete] = useState({ open: false, bol: null })
  const [confirmDialog, setConfirmDialog] = useState({ open: false, bol: null })
  const [confirmTemplateDialog, setConfirmTemplateDialog] = useState({ open: false, bol: null })
  const [sbPartyIdList, setSBPartyIdList] = useState([])
  const [sbPartyIdFetched, setSBPartyIdFetched] = useState(false)
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const [codeMaintenanceFetched, setCodeMaintenanceFetched] = useState(false)

  let columns = [
    {
      field: 'billNumber',
      sortKey: 'billNumber',
      title: getTranslatedText('BOL', 'billNumber')
    },
    {
      field: 'oceanBillNumber',
      sortKey: 'oceanBillNumber',
      title: getTranslatedText('BOL', 'oceanBillNumber')
    },
    {
      field: 'vesselName',
      sortKey: 'vesselName',
      title: getTranslatedText('BOL', 'vesselName')
    },
    {
      field: 'voyageNumber',
      sortKey: 'voyageNumber',
      title: getTranslatedText('BOL', 'voyageNumber')
    },
    {
      field: 'etaDateArr',
      sortKey: 'etaDateArr',
      title: getTranslatedText('BOL', 'etaDateArr'),
      render: (data) => (data.etaDateArr ? moment(data.etaDateArr).tz('Canada/Eastern').format('D MMM YYYY, HH:mm') : "")
    },
    {
      field: 'createdDate',
      sortKey: 'createdDate',
      title: getTranslatedText('BOL', 'createdDate'),
      render: (data) => data.createdDate && moment(data.createdDate).tz('Canada/Eastern').format('D MMM YYYY, HH:mm')
    },
    {
      field: 'submittedDate',
      sortKey: 'submittedDate',
      title: getTranslatedText('BOL', 'submittedDate'),
      render: (data) => data.submittedDate && moment(data.submittedDate).tz('Canada/Eastern').format('D MMM YYYY, HH:mm')
    },
    {
      field: 'status',
      sortKey: 'status',
      title: getTranslatedText('BOL', 'status'),
      render: (data) => {
        const status = getStatusMetadata(data.status)

        return (
          <Chip label={status.label} size='small'
            style={{ backgroundColor: status.color, color: status.contrastColor }}
          />
        )
      }
    },
    {
      field: 'manifestId',
      sortKey: 'manifestId',
      title: getTranslatedText('BOL', 'manifestId')
    }
  ]
  const [tableColumns, setTableColumns] = useState(columns)

  const filters = [
    {
      label: getTranslatedText('BOL', 'billNumber'),
      type: 'textfield',
      name: 'billNumber',
      operatorType: LIKE
    },
    {
      label: getTranslatedText('BOL', 'oceanBillNumber'),
      type: 'textfield',
      name: 'oceanBillNumber',
      operatorType: LIKE
    },
    {
      label: getTranslatedText('BOL', 'vesselName'),
      type: 'textfield',
      name: 'vesselName',
      operatorType: LIKE
    },
    {
      label: getTranslatedText('BOL', 'voyageNumber'),
      type: 'textfield',
      name: 'voyageNumber',
      operatorType: LIKE
    },
    {
      label: getTranslatedText('BOL', 'etaDateArr'),
      type: 'daterange',
      name: 'etaDateArr',
      operatorType: BETWEEN
    },
    {
      label: getTranslatedText('BOL', 'createdByLoginId'),
      type: 'textfield',
      name: 'createdByLoginId',
      operatorType: LIKE
    },
    {
      label: getTranslatedText('BOL', 'createdDate'),
      type: 'daterange',
      name: 'createdDate',
      operatorType: BETWEEN
    },
    {
      label: getTranslatedText('BOL', 'submittedDate'),
      type: 'daterange',
      name: 'submittedDate',
      operatorType: BETWEEN
    },
    {
      label: getTranslatedText('BOL', 'status'),
      type: 'checkbox',
      name: 'status',
      operatorType: IN,
      options: [
        { label: 'Draft', value: 'draft', filterValue: { value: '1005' } },
        { label: 'Accepted', value: 'accepted', filterValue: { value: '1000' } },
        { label: 'Rejected', value: 'rejected', filterValue: { value: '1001' } },
        { label: 'Sent', value: 'sent', filterValue: { value: '1008' } },
        { label: 'Status Notification', value: 'status_notification', filterValue: { value: '1006' } },
        { label: 'Cancel', value: 'cancel', filterValue: { value: '1011' } },
      ]
    },
    {
      label: getTranslatedText('BOL', 'manifestId'),
      type: 'textfield',
      name: 'manifestId',
      operatorType: LIKE
    },
  ]

  function handleDeleteBOL() {
    if (!confirmDelete.bol) return

    setConfirmDelete({ open: false })

    deleteRecord.execute(AceOcnBillofladingApiUrls.DELETE, confirmDelete.bol,
      () => {
        showNotification('success', 'Delete succeeded')
        setConfirmDelete({ open: false, bol: null })
        tableRef.current.performRefresh()
      },
      (error) => { console.log(error) }
    )
  }
  
   function handleDeleteBOLTemplate() {
    if (!confirmTemplateDialog.bol) return
    setConfirmTemplateDialog({ open: false })
    deleteRecord.execute(AceOcnBillofladingApiUrls.DELETE, confirmTemplateDialog.bol,
      () => {
        showNotification('success', 'Delete succeeded')
        setConfirmTemplateDialog({ open: false, bol: null })
        setBillTemplateDialog(false)
      },
      (error) => { console.log(error) }
    )
  }

  function DisableEditButton(rowData) {
    return rowData.status == '1008'
  }

  function DisableDeleteButton(rowData) {
    return rowData.status != '1005' && rowData.status != '1002'
  }

  async function exportBOL(data) {
    if (data.length > 0) {
      const config = { responseType: 'blob' }

      securedSendRequest.execute('POST', AceOcnBillofladingApiUrls.EXPORT_BOL, data,
        (response) => {
          let blob = new Blob([response.data])

          let first = 'ACEOCN_BOL_EXPORT_'
          let curDate = format(new Date(), 'yyyyMMddHHmmss')
          let filename = first + curDate + '.csv'
          let url = window.URL.createObjectURL(blob)
          let a = document.createElement('a')
          a.href = url
          a.download = filename
          a.click()
        },
        (error) => console.error(error),
        undefined,
        config,
        null
      )
    }
  }

  useEffect(() => {

    Promise.all([
      //HQ_ADMINISTRATOR
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'HQ_ADMINISTRATOR' },
          { field: 'code', operator: EQUAL, value: FileForUserGetCurrentLoginId() }
        ],
        undefined,
        'code'
      )
    ]).then(
      ([hqAdmin]) => {
        
        if(hqAdmin && hqAdmin[FileForUserGetCurrentLoginId()] == "true"){
          let hqAdminColumn =     {
            field: 'createdByLoginId',
            sortKey: 'createdByLoginId',
            title: getTranslatedText('BOL', 'createdByLoginId')
          };
          //append createdBy before created date
          columns.splice(-4, 0, hqAdminColumn);
          setTableColumns(columns)
        }
        setCodeMaintenanceFetched(true);
      }
    )

    let loginId = "";
    let userPreference = localStorage.getItem("userPreference");
    let fileForUserDetails = sessionStorage.getItem("fileForUserDetails");
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setSBPartyIdList([])
      setSBPartyIdFetched(true);
    } else {
      let userPreferenceJSON = JSON.parse(userPreference);
      loginId = userPreferenceJSON.tprUserDetail.userProfile.loginId;
    }
    console.log("loginId: " + JSON.stringify(loginId));

    if (loginId !== "") {
      function onSuccess(response) {
        setSBPartyIdList(response.data)
        setSBPartyIdFetched(true);
        console.log("sbPartyIdList: " + JSON.stringify(sbPartyIdList));
      }

      function onError(error) {
        console.log("sbPartyIdList Error: " + JSON.stringify(error));
      }

      function onComplete() { }
      const config = {}

      securedSendRequest.execute('POST', UserProfileApiUrls.SB_PARTYID_LIST, { loginId }, onSuccess, onError, onComplete, config, null);

    }
  }, [])

  let filter = GetFilterByUserIdCriteria("ACEOCN")

  return (
    <>
      {sbPartyIdFetched && codeMaintenanceFetched && ( <Table
        actions={[
          {
            label: 'Create Bill of Lading',
            buttonProps: {
              color: 'primary', size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => history.push(pathMap.ACE_OCN_BILLOFLADING_ADD_VIEW)
            }
          },
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => setBillTemplateDialog(true)
            },
            label: 'ACEOCN bill Template'
          }
        ]}
        columns={tableColumns}
        compact
        checkboxSelection
        fetch={{ url: AceOcnBillofladingApiUrls.GET }}
        fetchFilters={[
          { field: 'templateFlag', operator: EQUAL, value: '0' },
          { field: 'partyId', operator: IN, value: sbPartyIdList.length > 0 ? sbPartyIdList : FileForUserGetPartyId() },
          ...filter
        ]}
        filters={filters}
        onRowClick={(rowData) => {
          if (DisableEditButton(rowData)) {
            history.push(`${location.pathname}/view/${rowData.id}`)
          } else {
            history.push(`${location.pathname}/edit/${rowData.id}`)
          }
        }}
        rowActions={[
          {
            label: getTranslatedText('BOL', 'editButton'),
            icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
            disabled: (rowData) => DisableEditButton(rowData),
            onClick: (rowData) => history.push(`${location.pathname}/edit/${rowData.id}`),
            tooltip: (rowData) => DisableEditButton(rowData) ? 'Sent records cannot be Edited' : null
          },
          {
            label: getTranslatedText('BOL', 'deleteButton'),
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            disabled: (rowData) => DisableDeleteButton(rowData),
            onClick: (rowData) => setConfirmDelete({ open: true, bol: rowData }),
            tooltip: (rowData) => DisableDeleteButton(rowData) ? 'Only Draft records can be deleted' : null
          },
        ]}
        showNotification={showNotification}
        sortConfig={{ type: 'column', defaultField: 'createdDate', defaultDirection: 'DESC' }}
        persistSettings
        tableRef={tableRef}
        tablePreference={{ module: 'ACEOCN', key: 'bol_table_visible_column' }}
        selectActions={[
          {
            // disabled: (rows) => rows.length > 100,
            icon: <FontAwesomeIcon icon={['fal', 'link']} />,
            label: "Export Bill of Lading(s)",
            onClick: (rows) => {
              sweetalert("Exporting Bill of Lading in the background. The report will be downloaded automatically once ready.", "", "success");
              return exportBOL(rows);
            }
          }
        ]}
      />)}

      <ConfirmDialog
        isConfirmDialogOpen={confirmDelete.open}
        closeDialog={() => setConfirmDelete({ open: false, bol: null })}
        confirmDialog={handleDeleteBOL}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={getTranslatedText('BOL', 'deleteButton')}
      />
      <ConfirmDialog
        isConfirmDialogOpen={confirmTemplateDialog.open}
        closeDialog={() => setConfirmTemplateDialog({ open: false, bol: null })}
        confirmDialog={handleDeleteBOLTemplate}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={getTranslatedText('BOL', 'deleteButton')}
      />
      <TemplateSelectDialogWithOptionACEOCN
         manifestTemplateDialog={billTemplateDialog}
         setManifestTemplateDialog={setBillTemplateDialog}
         setConfirmDialog={setConfirmTemplateDialog}
         showNotification={showNotification}
         isTable={true}
         location={location}
        rowActions={[
          {
            icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
            label: 'Edit',
            onClick: (rowData) =>
              history.push(pathMap.ACE_OCN_BILLOFLADING+`/edit/${rowData.id}`),
            tooltip: () => 'edit'
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            label: 'Delete',
            onClick: (rowData) =>
            setConfirmTemplateDialog({ open: true, bol: rowData }),
            tooltip: () => 'delete'
          }
        ]}
      />
    </>
  )
}

export default TablePage
